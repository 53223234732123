//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-168:_1148,_7121,_8,_7212,_2296,_336,_7364,_1376;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-168')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-168', "_1148,_7121,_8,_7212,_2296,_336,_7364,_1376");
        }
      }catch (ex) {
        console.error(ex);
      }
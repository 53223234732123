import shopifyGenericDefaults from '../shopify-ella-generic/config.js';

shopifyGenericDefaults.Widgets.find((w) => w.name === 'RelatedItems').count = 4;
shopifyGenericDefaults.Widgets.find((w) => w.name === 'SearchBoxDialogButton').location.selector =
  '.header-mobile details-modal.header__search ';

export default {
  includes: ['shopify-ella-generic'],
  ...shopifyGenericDefaults,
};
